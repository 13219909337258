import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";

import MainLayout from "../layouts/MainLayout";

import PageHeader from "../components/common/page-header";
import Breadcrumb from "../components/common/breadcrumb";
import Seo from "../components/common/SEO";
import AuthWrapper from "../AuthWrapper";

const REQUEST_BASE_URL = `${process.env.GATSBY_API_BASE_URL}/api/v1/users/marketplaceuser`;
const REQUEST_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: `Bearer ${process.env.GATSBY_API_ACCESS_TOKEN}`,
};

export default class collections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      errors: null,
      processing: false,
      firstName: "",
      lastName: "",
      email: "",

      address: "",
      phone: "",
      city: "",
      state: "",
      zip: "",
    };

    this.validator = new SimpleReactValidator();
  }

  // Proceed to next step
  continues = (e) => {
    e.preventDefault();
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  // Go back to prev step
  back = (e) => {
    e.preventDefault();
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  // Handle fields change
  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  handleProcessing = (processing = false, errors = null) => {
    this.setState({ processing, errors });
  };
  submitForm = async (e) => {
    const { step } = this.state;
    if (this.validator.allValid()) {
      this.handleProcessing(true);
      const {
        email,
        firstName,
        lastName,
        phone,
        city,
        state,
        address,
        zip,
        password,
      } = this.state;
      const url = `${REQUEST_BASE_URL}`;
      const method = "post";
      const headers = REQUEST_HEADERS;

      const response = await fetch(url, {
        method,
        headers,
        body: JSON.stringify({
          email,
          firstName,
          lastName,
          password,
          address: {
            address,
            phone,
            city,
            state,
            zip,
          },
        }),
      });
      if (response.ok) {
        this.setState({ step: step + 1 });

        this.handleProcessing();
      } else {
        const err = await response.json();
        console.log(err);
        this.handleProcessing(
          false,
          err.errors.map((error) => error.msg)
        );
        return;
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const { step } = this.state;

    return (
      <LoadingOverlay>
        <Loader text="Processing ..." loading={this.state.processing} />

        <MainLayout>
          <AuthWrapper isSignUp={true}>
            <Seo title="Create Account" />
            <div className="main">
              <h1 className="d-none">Sign up</h1>
              <PageHeader title="Create Account" />
              <Breadcrumb />

              <div className="page-content">
                <div className="container">
                  <hr className="mb-4" />
                  <div className="login-page  pt-1 pb-1 pt-md-1 pb-md-1 pt-lg-1 pb-lg-1">
                    <div className="container">
                      <div className="form-box">
                        <div className="form-tab">
                          <div className="tab-content">
                            <div>
                              {" "}
                              <h5>Create your account</h5>
                              {this.state.errors && (
                                <div className="row">
                                  <div className="col">
                                    <div
                                      className="alert alert-danger w-100"
                                      role="alert"
                                    >
                                      {typeof this.state.errors === "string" ? (
                                        this.state.errors
                                      ) : (
                                        <ul>
                                          {this.state.errors.map((err, i) => (
                                            <li
                                              key={i}
                                              style={{ display: "list-item" }}
                                            >
                                              {err}
                                            </li>
                                          ))}
                                        </ul>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>{" "}
                            {this.state.step === 1 ? (
                              <>
                                <form action="#">
                                  <div className="form-group">
                                    <label htmlFor="singin-email-2">
                                      Email address *
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="singin-email-2"
                                      name="email"
                                      onChange={this.handleChange("email")}
                                      defaultValue={this.state.email}
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="singin-password-2">
                                      First Name *
                                    </label>
                                    <input
                                      className="form-control"
                                      id="singin-password-2"
                                      name="firstName"
                                      onChange={this.handleChange("firstName")}
                                      defaultValue={this.state.firstName}
                                      required
                                    />

                                    {this.validator.message(
                                      "firstName",
                                      this.state.firstName,
                                      "required|alpha"
                                    )}
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="singin-password-2">
                                      Last Name *
                                    </label>
                                    <input
                                      className="form-control"
                                      id="singin-password-2"
                                      name="lastName"
                                      onChange={this.handleChange("lastName")}
                                      defaultValue={this.state.lastName}
                                      required
                                    />

                                    {this.validator.message(
                                      "lastName",
                                      this.state.lastName,
                                      "required|alpha"
                                    )}
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="singin-password-2">
                                      Password *
                                    </label>
                                    <input
                                      className="form-control"
                                      id="singin-password-2"
                                      name="password"
                                      type="password"
                                      onChange={this.handleChange("password")}
                                      defaultValue={this.state.password}
                                      required
                                    />

                                    {this.validator.message(
                                      "password",
                                      this.state.password,
                                      "required"
                                    )}
                                  </div>{" "}
                                  <div className="form-group">
                                    <label htmlFor="singin-password-2">
                                      Confirm Password *
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      id="singin-password-2"
                                      name="confirmPassword"
                                      onChange={this.handleChange(
                                        "confirmPassword"
                                      )}
                                      defaultValue={this.state.confirmPassword}
                                      required
                                    />

                                    {this.validator.message(
                                      "confirmPassword",
                                      this.state.confirmPassword,
                                      `required|in:${this.state.password}`,
                                      {
                                        messages: {
                                          in: "Passwords need to match!",
                                        },
                                      }
                                    )}
                                  </div>
                                </form>
                                {/* <div className="form-choice">
                                <p className="text-center">or sign in with</p>
                                <div className="row">
                                  <div className="col-sm-6">
                                    <a
                                      href="pages/login/#a"
                                      className="btn btn-login btn-g"
                                    >
                                      <i className="icon-google"></i>
                                      Login With Google
                                    </a>
                                  </div>
                                  <div className="col-sm-6">
                                    <a
                                      href="pages/login/#a"
                                      className="btn btn-login btn-f"
                                    >
                                      <i className="icon-facebook-f"></i>
                                      Login With Facebook
                                    </a>
                                  </div>
                                </div>
                              </div> */}
                                {/* <div className="form-group">
                                  <label htmlFor="register-email-3">
                                    address
                                  </label>
                                  <input
                                    className="form-control"
                                    id="register-email-3"
                                    name="address"
                                    onChange={this.handleChange("address")}
                                    defaultValue={this.state.address}
                                    required
                                  />

                                  {this.validator.message(
                                    "address",
                                    this.state.address,
                                    "required"
                                  )}
                                </div>
                                <div className="form-group">
                                  <label htmlFor="register-password-4">
                                    city{" "}
                                  </label>
                                  <input
                                    className="form-control"
                                    id="register-password-4"
                                    name="city"
                                    onChange={this.handleChange("city")}
                                    defaultValue={this.state.city}
                                    required
                                  />

                                  {this.validator.message(
                                    "city",
                                    this.state.city,
                                    "required|alpha"
                                  )}
                                </div>
                                <div className="form-group">
                                  <label htmlFor="state">
                                    State / County *
                                  </label>
                                  <select
                                    id="state"
                                    className="form-control"
                                    name="state"
                                    value={this.state.state}
                                    onBlur={this.handleChange("state")}
                                    onChange={this.handleChange("state")}
                                  >
                                    <option value={""} disabled>
                                      Select State
                                    </option>
                                    {States.map((state, i) => (
                                      <option key={i} value={state}>
                                        {state}
                                      </option>
                                    ))}
                                  </select>

                                  {this.validator.message(
                                    "state",
                                    this.state.state,
                                    "required|alpha"
                                  )}
                                </div>{" "}
                                <div className="form-group">
                                  <label htmlFor="register-password-8">
                                    zip{" "}
                                  </label>
                                  <input
                                    className="form-control"
                                    id="register-password-5"
                                    name="zip"
                                    onChange={this.handleChange("zip")}
                                    defaultValue={this.state.zip}
                                    required
                                  />
                                  {this.validator.message(
                                    "zip",
                                    this.state.zip,
                                    "required"
                                  )}
                                </div>
                                <div className="form-group">
                                  <label htmlFor="register-password-8">
                                    phone{" "}
                                  </label>
                                  <input
                                    className="form-control"
                                    id="register-password-5"
                                    name="phone"
                                    onChange={this.handleChange("phone")}
                                    defaultValue={this.state.phone}
                                    required
                                  />

                                  {this.validator.message(
                                    "phone",
                                    this.state.phone,
                                    "required"
                                  )}
                                </div> */}
                                <div className="form-footer">
                                  <button
                                    onClick={this.submitForm}
                                    type="submit"
                                    className="btn btn-outline-primary-2"
                                  >
                                    <span>Submit</span>
                                    <i className="icon-long-arrow-right"></i>
                                  </button>

                                  {/* <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="register-policy-2"
                                    required
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="register-policy-2"
                                  >
                                    I agree to the{" "}
                                    <a href="pages/login/#a">privacy policy</a>{" "}
                                    *
                                  </label>
                                </div> */}
                                </div>
                              </>
                            ) : step === 2 ? (
                              <>{"Success! Your  account has been created."}</>
                            ) : (
                              "Failed"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AuthWrapper>
        </MainLayout>
      </LoadingOverlay>
    );
  }
}
